import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, set } from 'firebase/database';
import Sidebar from './Sidebar';

function UserDetailsPage() {
  const { userId } = useParams();  // Retrieve userId from the URL
  const [user, setUser] = useState(null);  // State to store the fetched user data
  const [loading, setLoading] = useState(true);  // Loading state to show a spinner or loading message
  const [error, setError] = useState(null);  // State for error handling
  const navigate = useNavigate();  // Navigate hook for redirecting

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return 'N/A';
  
    // Convert phoneNumber to a string in case it's not already
    const phoneStr = String(phoneNumber);
  
    // Remove non-numeric characters
    const cleaned = phoneStr.replace(/\D/g, '');
  
    // Extract country code and the rest of the number
    const countryCodeMatch = cleaned.match(/^(\d{1,3})(\d{7,10})$/);
  
    if (countryCodeMatch) {
      const countryCode = countryCodeMatch[1]; // Extracts country code
      const restOfNumber = countryCodeMatch[2]; // Extracts the rest of the number
  
      // Format the number as "+(country code) restOfNumber"
      return `+(${countryCode}) ${restOfNumber}`;
    }
  
    return phoneStr; // Return as is if it doesn't match the pattern
  };
  
  

  const getTimeRemaining = (blockedUntil) => {
    const remainingTime = blockedUntil - Date.now();
    if (remainingTime <= 0) return 'No time remaining';
    const hours = Math.floor(remainingTime / 3600000);
    const minutes = Math.floor((remainingTime % 3600000) / 60000);
    return `${hours} hours ${minutes} minutes remaining`;
  };

  const blockUser = async (userId, unblock = false) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, `users/${userId}`);
      if (unblock) {
        // Unblock the user
        await set(userRef, {
          ...user,
          blockedUntil: null,
        });
      } else {
        // Block the user for 24 hours
        const blockedUntil = Date.now() + 24 * 60 * 60 * 1000; // 24 hours from now
        await set(userRef, {
          ...user,
          blockedUntil,
        });
      }
    } catch (err) {
      console.error("Error blocking/unblocking user:", err);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const db = getDatabase();
        const userRef = ref(db, `users/${userId}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          setUser(snapshot.val());  // Set user data if found
        } else {
          setError('User not found');  // Handle case where user data doesn't exist
        }
      } catch (err) {
        setError('Error fetching user data');
        console.error(err);  // Log error to console
      } finally {
        setLoading(false);  // Stop loading
      }
    };

    fetchUserData();
  }, [userId]);  // Fetch user data when userId changes

  if (loading) {
    return <div>Loading...</div>;  // Show loading text or spinner
  }

  if (error) {
    return <div>{error}</div>;  // Show error message
  }

  return (
    <div>
                <Sidebar />
    <div className="container mt-5">
  <div className="row justify-content-center">
    <div className="col-md-8 col-lg-6">
      <div className="card shadow-sm border-0">
        <div className="card-body">
          <h2 className="text-center text-primary mb-4">User Details</h2>
          {user && (
            <div>
              {/* <h3 className="text-center mb-4">{user.username}</h3> */}

              <div className="row">
                {/* General User Info */}
                <div className="col-12 mb-3">
                  <h5 className="text-primary">General Information</h5>
                  <hr />
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Username:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.username}
                </div>
                
                <div className="col-12 col-md-6 mb-2">
                  <strong>Email:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.email}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>First Name:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.firstname}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Last Name:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.lastname}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Country:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.country}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Created:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {formatDate(user.timestamp)}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Last Logged In:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {new Date(user.lastLoginDate).toLocaleString()}
                </div>

                {/* Additional User Info */}
                <div className="col-12 mt-4 mb-3">
                  <h5 className="text-primary">Additional Information</h5>
                  <hr />
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Gender:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.selectedGender || 'N/A'}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Phone Number:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {formatPhoneNumber(user.phoneNumber)}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Promo Email:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.promoemail !== undefined ? user.promoemail.toString() : 'false'}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>UID:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.uid || 'N/A'}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Role:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.role || 'N/A'}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Tokens:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.tokens || 'N/A'}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>Wallet Address:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.walletAddress || 'N/A'}
                </div>

                <div className="col-12 col-md-6 mb-2">
                  <strong>User Status:</strong>
                </div>
                <div className="col-12 col-md-6 mb-2">
                  {user.userStatus || 'N/A'}
                </div>

                {/* Block/Unblock User Section */}
                <div className="col-12 mt-4">
                  <h5 className="text-primary">Account Status</h5>
                  <hr />
                </div>
                {user.blockedUntil && user.blockedUntil > Date.now() ? (
                  <>
                    <div className="col-12 col-md-6 mb-2">
                      <strong>Blocked Until:</strong>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                      {new Date(user.blockedUntil).toLocaleString()}
                    </div>

                    <div className="col-12 col-md-6 mb-2">
                      <strong>Time Remaining:</strong>
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                      {getTimeRemaining(user.blockedUntil)}
                    </div>

                    <div className="col-12 mt-2">
                      <button
                        className="btn btn-danger btn-sm w-100"
                        onClick={() => blockUser(user.id, true)}
                      >
                        Unblock
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="col-12">
                    <strong>Block User:</strong>
                    <button
                      className="btn btn-warning btn-sm w-100 mt-2"
                      onClick={() => blockUser(user.id)}
                    >
                      Block for 24h
                    </button>
                  </div>
                )}
              </div>
            </div>
            
          )}
          <br />
             <button 
            className="btn btn-dark mb-4"
            onClick={() => navigate(-1)}
          >
            Back to Users List
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

  );
}

export default UserDetailsPage;
