import React, { useEffect, useState } from 'react';
import { getDatabase, ref, set, push, update, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Navbar from './Navbar';
import { Spinner, Modal, Button, Form, Card } from 'react-bootstrap';
import './Home.css';
import { auth, db } from './firebase';

const Home = () => {
  const [fixtures, setFixtures] = useState([]);
  const [userBets, setUserBets] = useState({});
  const [odds, setOdds] = useState({});
  const [prizePots, setPrizePots] = useState({});
  const [showBetModal, setShowBetModal] = useState(false);
  const [selectedFixture, setSelectedFixture] = useState(null);
  const [selectedOutcome, setSelectedOutcome] = useState(null);
  const [betAmount, setBetAmount] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [userTokens, setUserTokens] = useState(0);

  // Authentication listener
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        
        // Fetch user tokens
        try {
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userRef);
          
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUserTokens(userData.tokens || 0);
          }
        } catch (error) {
          console.error("Error fetching user tokens:", error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch Fixtures
  useEffect(() => {
    const fetchUpcomingFixtures = async () => {
      try {
        const response = await fetch(
          "https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2024",
          {
            headers: {
              'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg==',
            },
          }
        );
        const data = await response.json();
        const upcomingFixtures = data.filter(
          (match) => match.fixture.status.short !== "FT"
        );

        const nextTwoFixtures = upcomingFixtures.slice(0, 10);
        setFixtures(nextTwoFixtures);

        const db = getDatabase();
        const fixturesOddsAndPots = {};
        const oddsState = {};
        const prizePotState = {};

        for (const fixture of nextTwoFixtures) {
          const fixtureRef = ref(db, `fixtures/${fixture.fixture.id}`);
          const snapshot = await get(fixtureRef);

          if (!snapshot.exists()) {
            const newFixture = {
              homeTeam: fixture.teams.home.name,
              awayTeam: fixture.teams.away.name,
              odds: { teamA: 0.34, draw: 0.34, teamB: 0.34 },
              prizePot: 0,
            };

            await set(fixtureRef, newFixture);
            oddsState[fixture.fixture.id] = newFixture.odds;
            prizePotState[fixture.fixture.id] = newFixture.prizePot;
          } else {
            const fixtureData = snapshot.val();
            oddsState[fixture.fixture.id] = fixtureData.odds;
            prizePotState[fixture.fixture.id] = fixtureData.prizePot || 0;
          }
        }

        setOdds(oddsState);
        setPrizePots(prizePotState);
      } catch (error) {
        console.error("Error fetching fixture data:", error);
      }
    };

    fetchUpcomingFixtures();
  }, []);

  // Place Bet Function
  const placeBet = async () => {
    if (!currentUser) {
      alert("Please log in to place a bet.");
      return;
    }

    const betAmountNum = parseFloat(betAmount);

    // Validation checks
    if (isNaN(betAmountNum) || betAmountNum <= 0) {
      alert("Please enter a valid bet amount.");
      return;
    }

    if (betAmountNum > userTokens) {
      alert("Insufficient tokens. Please add more tokens.");
      return;
    }

    try {
      const db = getDatabase();
      
      // Create a new bet entry
      const betRef = push(ref(db, 'bets'));
      await set(betRef, {
        userId: currentUser.uid,
        fixtureId: selectedFixture.fixture.id,
        outcome: selectedOutcome,
        amount: betAmountNum,
        timestamp: Date.now()
      });

      // Update user tokens
      const userRef = ref(db, `users/${currentUser.uid}`);
      await update(userRef, {
        tokens: userTokens - betAmountNum
      });

      // Update fixture prize pot and odds
      const fixtureRef = ref(db, `fixtures/${selectedFixture.fixture.id}`);
      const fixtureSnapshot = await get(fixtureRef);
      const fixtureData = fixtureSnapshot.val();
      
      const newPrizePot = (fixtureData.prizePot || 0) + betAmountNum;
      const newOdds = adjustOdds(selectedOutcome, betAmountNum);

      await update(fixtureRef, {
        prizePot: newPrizePot,
        odds: newOdds
      });

      // Update local state
      setUserTokens(userTokens - betAmountNum);
      setPrizePots(prev => ({
        ...prev,
        [selectedFixture.fixture.id]: newPrizePot
      }));
      setOdds(prev => ({
        ...prev,
        [selectedFixture.fixture.id]: newOdds
      }));

      // Close modal and reset
      setShowBetModal(false);
      setBetAmount('');
      setSelectedOutcome(null);
      setSelectedFixture(null);

      alert("Bet placed successfully!");
    } catch (error) {
      console.error("Error placing bet:", error);
      alert("Failed to place bet. Please try again.");
    }
  };

  // Adjust Odds Function
  const adjustOdds = (selectedBet, amount) => {
    const totalAdjustment = 0.01 * (amount / 10);
    const adjustedOdds = { ...odds[selectedFixture.fixture.id] }; // Use the odds for the specific fixture
    
    const homeTeamName = selectedFixture.teams.home.name;
    const awayTeamName = selectedFixture.teams.away.name;
  
    // Adjust odds for the selected bet
    if (selectedBet === homeTeamName) {
      adjustedOdds.teamA += totalAdjustment;
      adjustedOdds.teamB -= totalAdjustment / 2;
      adjustedOdds.draw -= totalAdjustment / 2;
    } else if (selectedBet === awayTeamName) {
      adjustedOdds.teamB += totalAdjustment;
      adjustedOdds.teamA -= totalAdjustment / 2;
      adjustedOdds.draw -= totalAdjustment / 2;
    } else if (selectedBet === "Draw") {
      adjustedOdds.draw += totalAdjustment;
      adjustedOdds.teamA -= totalAdjustment / 2;
      adjustedOdds.teamB -= totalAdjustment / 2;
    }
  
    // Ensure odds for all outcomes remain valid (e.g., >= 0.1)
    Object.keys(adjustedOdds).forEach((key) => {
      if (adjustedOdds[key] < 0.1) {
        adjustedOdds[key] = 0.1;
      }
    });
  
    return adjustedOdds;
  };

  // Bet Modal Opener
  const openBetModal = (fixture, outcome) => {
    if (!currentUser) {
      alert("Please log in to place a bet.");
      return;
    }
    setSelectedFixture(fixture);
    setSelectedOutcome(outcome);
    setShowBetModal(true);
  };

  // Display loading
  if (fixtures.length === 0) {
    return <div className='custom-page .dark '><h2 className='text-center'><Spinner /></h2></div>;
  }

  

  return (
    <div>
      <Navbar />
      <div className="full-page">
        <div className="custom-page">
          <section className="dark">
            <div className="container py-4">
            <div class="container">
  <div class="row">
    <div class="col text-light col-lg-2 sidebar">
    <div class="card bg-transparent border-light">
  <div class="card-body text-center text-light ">
Sidebar
  </div>
</div>
    </div>
    <div class="col-lg-8">
    <div className="row">
                {fixtures.map((fixture) => (
                  <div key={fixture.fixture.id} className="col-lg-12 col-sm-12 mb-4">
                    <article className="postcard dark blue">
                      <div className="postcard__text">
                        <h1 className="postcard__title blue">
                          {fixture.teams.home.name} vs {fixture.teams.away.name} 
                        </h1>
    <div className="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                {/* <i className="fas fa-calendar-alt mr-2">{convertToFormattedDateTime(fixture.fixture.date)}</i> <br /> */}
                  <i className="fas fa-calendar-alt mr-2"></i>Vol:  {prizePots[fixture.fixture.id]?.toFixed(2) || '0.00'} Tokens -                    <span class="badge bg-dark">                {fixture.fixture.date} </span>

                </time>
              </div>
                        <div className="odds-section">
                          <div className="team-names">
                            <div className="home-team-name">{fixture.teams.home.name}</div>
                            <div className="draw-team-name">Draw</div>
                            <div className="away-team-name">{fixture.teams.away.name}</div>
                          </div>
                          <div className="bet-buttons">
                            <button
                              onClick={() => openBetModal(fixture, fixture.teams.home.name)}
                              className="bet-button team-a"
                            >
                              {odds[fixture.fixture.id]?.teamA.toFixed(2)}
                            </button>
                            <button
                              onClick={() => openBetModal(fixture, "Draw")}
                              className="bet-button draw"
                            >
                              {odds[fixture.fixture.id]?.draw.toFixed(2)}
                            </button>
                            <button
                              onClick={() => openBetModal(fixture, fixture.teams.away.name)}
                              className="bet-button team-b"
                            >
                              {odds[fixture.fixture.id]?.teamB.toFixed(2)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                ))}
              </div>
    </div>
    <div className="col-lg-4 text-light text-end fixed-bet-card">
  <Card className="bet-card">
    <Card.Header>
      <Card.Title className="text-center">Place Your Bet</Card.Title>
    </Card.Header>
    <Card.Body>
      {selectedFixture && selectedOutcome && (
        <>
          <div className="bet-details">
            <h5 className="text-center">Amount</h5>
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="Enter bet amount"
                value={betAmount}
                onChange={(e) => setBetAmount(e.target.value)}
                max={userTokens}
                min={1}
              />
            </Form.Group>
            {betAmount && parseFloat(betAmount) > 0 && (
              <div className="potential-returns mt-3">
                <h5 className="text-center">Potential Returns</h5>
                <div className="returns-breakdown">
                  <div className="return-row">
                    <span>Amount</span>
                    <strong>${parseFloat(betAmount).toFixed(2)}</strong>
                  </div>
                  <div className="return-row">
                    <span>Balance</span>
                    <strong>${(userTokens - parseFloat(betAmount)).toFixed(2)}</strong>
                  </div>
                  <div className="return-row">
                    <span>Avg price</span>
                    <strong>
                      {(() => {
                        const oddKey =
                          selectedOutcome === selectedFixture.teams.home.name
                            ? "teamA"
                            : selectedOutcome === selectedFixture.teams.away.name
                            ? "teamB"
                            : "draw";
                        return `${(odds[selectedFixture.fixture.id][oddKey] * 100).toFixed(0)}¢`;
                      })()}
                    </strong>
                  </div>
                  <div className="return-row">
                    <span>Potential return</span>
                    <strong>
                      {(() => {
                        const oddKey =
                          selectedOutcome === selectedFixture.teams.home.name
                            ? "teamA"
                            : selectedOutcome === selectedFixture.teams.away.name
                            ? "teamB"
                            : "draw";
                        const currentOdds = odds[selectedFixture.fixture.id][oddKey];
                        const betAmountNum = parseFloat(betAmount);

                        // Calculate potential return as total amount (stake + profit)
                        const potentialReturn = betAmountNum * (1 / currentOdds);

                        // Calculate percentage return
                        const returnPercentage =
                          ((potentialReturn / betAmountNum) - 1) * 100;

                        return `$${potentialReturn.toFixed(2)} (${returnPercentage.toFixed(0)}%)`;
                      })()}
                    </strong>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Card.Body>
    <Card.Footer className="text-center">
      <Button variant="secondary" onClick={() => setBetAmount("")}>
        Cancel
      </Button>
      <Button variant="primary" onClick={placeBet}>
        Place Bet
      </Button>
    </Card.Footer>
  </Card>
</div>

  </div>
</div>
           
            </div>
          </section>
        </div>
      </div>

      {/* Bet Modal */}
      
    </div>
  );
};

export default Home;