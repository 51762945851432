import React, { useState, useEffect, useMemo } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { ref, push, getDatabase, set } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import Navbar from '../Navbar';
import '../App.css'
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list'
import Image123 from '../LogoWhite3.png'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [tokens, setTokens] = useState('');
  const [username, setUsername] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedTokens, setSelectedToken] = useState('');
  const [promoemail, setPromoEmail] = useState(false);
  const [iAgree, setIAgree] = useState(false);
  const [ageVerified, setAgeVerified] = useState(false);
  const navigate = useNavigate();
  const prohibited = [/* your list of prohibited words */];
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData().map(country => ({ value: country.label, label: country.label })), []);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await user.reload(); // Ensure latest user info is loaded
        if (user.emailVerified) {
          navigate('/');
        }
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);
  

  useEffect(() => {
    if (auth.currentUser) {
      auth.currentUser.reload().then(() => {
        if (auth.currentUser.emailVerified) {
          alert("Your email has been verified! You can now log in.");
        }
      });
    }
  }, [auth]);
  

  const signup = async () => {
    try {
      const authInstance = getAuth();
      const usernameLowerCase = username.toLowerCase();
  
      for (const word of prohibited) {
        if (usernameLowerCase.includes(word.toLowerCase())) {
          setError('Please do not use prohibited words');
          return;
        }
      }
  
      const userCredential = await createUserWithEmailAndPassword(authInstance, email, password);
      const user = userCredential.user;
  
      await sendEmailVerification(user);
      setVerificationSent(true);
      alert("Verification email sent. Please verify your email before logging in.");
  
      await updateProfile(user, {
        displayName: `${firstname} (${username}) (${lastname}) (${country}) (${tokens})`,
        emailVerified: false,
      });
  
      const phoneNumberAsNumber = parseInt(phoneNumber.replace(/\D/g, ''), 10);
  
      // User data with walletAddress and userStatus
      const userData = {
        uid: user.uid,
        firstname,
        lastname,
        username,
        email,
        country,
        selectedGender,
        tokens: 0, // Ensure tokens start as null
        phoneNumber: phoneNumberAsNumber,
        promoemail,
        role: 'user', // Default role
        walletAddress: '', // Wallet address initialized as null
        userStatus: 'active', // User status set to active upon signup
        timestamp: new Date().toISOString(),
        lastLoginDate: new Date().toISOString(),
      };
  
      // Store user data in Firebase under the user's uid
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`); // Use the uid as the key
      await set(userRef, userData);
  
    } catch (error) {
      setError(error.message);
      setTimeout(() => setError(''), 5000);
    }
  };
  

const isFormValid = () => {
  const hasValidUsername = !prohibited.some(word => username.toLowerCase().includes(word.toLowerCase()));

  const valid = (
    firstname &&
    lastname &&
    country &&
    selectedGender &&
    username &&
    email &&
    password &&
    phoneNumber &&
    // tokens &&
    iAgree &&
    ageVerified &&
    hasValidUsername &&
    !error
  );

  console.log({
    firstname,
    lastname,
    country,
    selectedGender,
    username,
    email,
    password,
    phoneNumber,
    tokens,
    iAgree,
    ageVerified,
    error,
    hasValidUsername,
    valid
  });

  return valid;
};




return (
  <div>
    <Navbar />
    <div className='App-headerV8'>
      <section className=" py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-6">
              <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-7 p-xl-7 ">
                  <div className="text-center mb-3">
                    <a href="#!">
                      <img src={Image123} alt="BootstrapBrain Logo" width="160" height="50" />
                    </a>
                  </div>
                  <h2 className="fs-6 fw-normal text-center text-secondary mb-4">{t('register_description')}</h2>
                  <div>
                    <div className="row gy-2 overflow-hidden">
                      {/* Input fields and selects */}
                      <div className="col-lg-6">
                        <div className='form-floating mb-3'>
                          <input type="text" className="form-control" name="firstName" id="firstName" placeholder="First Name" maxLength={20} required value={firstname} onChange={(e) => setFirstName(e.target.value)} />
                          <label htmlFor="firstName" className="form-label">First Name </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className='form-floating mb-3'>
                          <input type="text" className="form-control" name="lastName" id="lastName" placeholder="Last Name" maxLength={20} required value={lastname} onChange={(e) => setLastName(e.target.value)} />
                          <label htmlFor="lastName" className="form-label">Last Name </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-floating mb-3">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            required
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option value="" disabled>Country</option>
                            <option value="Afghanistan">Afghanistan</option>
      <option value="Albania">Albania</option>
      <option value="Algeria">Algeria</option>
      <option value="Andorra">Andorra</option>
      <option value="Angola">Angola</option>
      <option value="Antigua and Barbuda">Antigua and Barbuda</option>
      <option value="Argentina">Argentina</option>
      <option value="Armenia">Armenia</option>
      <option value="Australia">Australia</option>
      <option value="Austria">Austria</option>
      <option value="Azerbaijan">Azerbaijan</option>
      <option value="Bahamas">Bahamas</option>
      <option value="Bahrain">Bahrain</option>
      <option value="Bangladesh">Bangladesh</option>
      <option value="Barbados">Barbados</option>
      <option value="Belarus">Belarus</option>
      <option value="Belgium">Belgium</option>
      <option value="Belize">Belize</option>
      <option value="Benin">Benin</option>
      <option value="Bhutan">Bhutan</option>
      <option value="Bolivia">Bolivia</option>
      <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
      <option value="Botswana">Botswana</option>
      <option value="Brazil">Brazil</option>
      <option value="Brunei">Brunei</option>
      <option value="Bulgaria">Bulgaria</option>
      <option value="Burkina Faso">Burkina Faso</option>
      <option value="Burundi">Burundi</option>
      <option value="Cabo Verde">Cabo Verde</option>
      <option value="Cambodia">Cambodia</option>
      <option value="Cameroon">Cameroon</option>
      <option value="Canada">Canada</option>
      <option value="Central African Republic">Central African Republic</option>
      <option value="Chad">Chad</option>
      <option value="Chile">Chile</option>
      <option value="China">China</option>
      <option value="Colombia">Colombia</option>
      <option value="Comoros">Comoros</option>
      <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
      <option value="Congo, Republic of the">Congo, Republic of the</option>
      <option value="Costa Rica">Costa Rica</option>
      <option value="Croatia">Croatia</option>
      <option value="Cuba">Cuba</option>
      <option value="Cyprus">Cyprus</option>
      <option value="Czech Republic">Czech Republic</option>
      <option value="Denmark">Denmark</option>
      <option value="Djibouti">Djibouti</option>
      <option value="Dominica">Dominica</option>
      <option value="Dominican Republic">Dominican Republic</option>
      <option value="East Timor">East Timor</option>
      <option value="Ecuador">Ecuador</option>
      <option value="Egypt">Egypt</option>
      <option value="El Salvador">El Salvador</option>
      <option value="Equatorial Guinea">Equatorial Guinea</option>
      <option value="Eritrea">Eritrea</option>
      <option value="Estonia">Estonia</option>
      <option value="Eswatini">Eswatini</option>
      <option value="Ethiopia">Ethiopia</option>
      <option value="Fiji">Fiji</option>
      <option value="Finland">Finland</option>
      <option value="France">France</option>
      <option value="Gabon">Gabon</option>
      <option value="Gambia">Gambia</option>
      <option value="Georgia">Georgia</option>
      <option value="Germany">Germany</option>
      <option value="Ghana">Ghana</option>
      <option value="Greece">Greece</option>
      <option value="Grenada">Grenada</option>
      <option value="Guatemala">Guatemala</option>
      <option value="Guinea">Guinea</option>
      <option value="Guinea-Bissau">Guinea-Bissau</option>
      <option value="Guyana">Guyana</option>
      <option value="Haiti">Haiti</option>
      <option value="Honduras">Honduras</option>
      <option value="Hungary">Hungary</option>
      <option value="Iceland">Iceland</option>
      <option value="India">India</option>
      <option value="Indonesia">Indonesia</option>
      <option value="Iran">Iran</option>
      <option value="Iraq">Iraq</option>
      <option value="Ireland">Ireland</option>
      <option value="Israel">Israel</option>
      <option value="Italy">Italy</option>
      <option value="Jamaica">Jamaica</option>
      <option value="Japan">Japan</option>
      <option value="Jordan">Jordan</option>
      <option value="Kazakhstan">Kazakhstan</option>
      <option value="Kenya">Kenya</option>
      <option value="Kiribati">Kiribati</option>
      <option value="Korea, North">Korea, North</option>
      <option value="Korea, South">Korea, South</option>
      <option value="Kosovo">Kosovo</option>
      <option value="Kuwait">Kuwait</option>
      <option value="Kyrgyzstan">Kyrgyzstan</option>
      <option value="Laos">Laos</option>
      <option value="Latvia">Latvia</option>
      <option value="Lebanon">Lebanon</option>
      <option value="Lesotho">Lesotho</option>
      <option value="Liberia">Liberia</option>
      <option value="Libya">Libya</option>
      <option value="Liechtenstein">Liechtenstein</option>
      <option value="Lithuania">Lithuania</option>
      <option value="Luxembourg">Luxembourg</option>
      <option value="Madagascar">Madagascar</option>
      <option value="Malawi">Malawi</option>
      <option value="Malaysia">Malaysia</option>
      <option value="Maldives">Maldives</option>
      <option value="Mali">Mali</option>
      <option value="Malta">Malta</option>
      <option value="Marshall Islands">Marshall Islands</option>
      <option value="Mauritania">Mauritania</option>
      <option value="Mauritius">Mauritius</option>
      <option value="Mexico">Mexico</option>
      <option value="Micronesia">Micronesia</option>
      <option value="Moldova">Moldova</option>
      <option value="Monaco">Monaco</option>
      <option value="Mongolia">Mongolia</option>
      <option value="Montenegro">Montenegro</option>
      <option value="Morocco">Morocco</option>
      <option value="Mozambique">Mozambique</option>
      <option value="Myanmar">Myanmar</option>
      <option value="Namibia">Namibia</option>
      <option value="Nauru">Nauru</option>
      <option value="Nepal">Nepal</option>
      <option value="Netherlands">Netherlands</option>
      <option value="New Zealand">New Zealand</option>
      <option value="Nicaragua">Nicaragua</option>
      <option value="Niger">Niger</option>
      <option value="Nigeria">Nigeria</option>
      <option value="North Macedonia">North Macedonia</option>
      <option value="Norway">Norway</option>
      <option value="Oman">Oman</option>
      <option value="Pakistan">Pakistan</option>
      <option value="Palau">Palau</option>
      <option value="Palestine">Palestine</option>
      <option value="Panama">Panama</option>
      <option value="Papua New Guinea">Papua New Guinea</option>
      <option value="Paraguay">Paraguay</option>
      <option value="Peru">Peru</option>
      <option value="Philippines">Philippines</option>
      <option value="Poland">Poland</option>
      <option value="Portugal">Portugal</option>
      <option value="Qatar">Qatar</option>
      <option value="Romania">Romania</option>
      <option value="Russia">Russia</option>
      <option value="Rwanda">Rwanda</option>
      <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
      <option value="Saint Lucia">Saint Lucia</option>
      <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
      <option value="Samoa">Samoa</option>
      <option value="San Marino">San Marino</option>
      <option value="Sao Tome and Principe">Sao Tome and Principe</option>
      <option value="Saudi Arabia">Saudi Arabia</option>
      <option value="Senegal">Senegal</option>
      <option value="Serbia">Serbia</option>
      <option value="Seychelles">Seychelles</option>
      <option value="Sierra Leone">Sierra Leone</option>
      <option value="Singapore">Singapore</option>
      <option value="Slovakia">Slovakia</option>
      <option value="Slovenia">Slovenia</option>
      <option value="Solomon Islands">Solomon Islands</option>
      <option value="Somalia">Somalia</option>
      <option value="South Africa">South Africa</option>
      <option value="South Sudan">South Sudan</option>
      <option value="Spain">Spain</option>
      <option value="Sri Lanka">Sri Lanka</option>
      <option value="Sudan">Sudan</option>
      <option value="Suriname">Suriname</option>
      <option value="Sweden">Sweden</option>
      <option value="Switzerland">Switzerland</option>
      <option value="Syria">Syria</option>
      <option value="Taiwan">Taiwan</option>
      <option value="Tajikistan">Tajikistan</option>
      <option value="Tanzania">Tanzania</option>
      <option value="Thailand">Thailand</option>
      <option value="Togo">Togo</option>
      <option value="Tonga">Tonga</option>
      <option value="Trinidad and Tobago">Trinidad and Tobago</option>
      <option value="Tunisia">Tunisia</option>
      <option value="Turkey">Turkey</option>
      <option value="Turkmenistan">Turkmenistan</option>
      <option value="Tuvalu">Tuvalu</option>
      <option value="Uganda">Uganda</option>
      <option value="Ukraine">Ukraine</option>
      <option value="United Arab Emirates">United Arab Emirates</option>
      <option value="United Kingdom">United Kingdom</option>
      <option value="United States">United States</option>
      <option value="Uruguay">Uruguay</option>
      <option value="Uzbekistan">Uzbekistan</option>
      <option value="Vanuatu">Vanuatu</option>
      <option value="Vatican City">Vatican City</option>
      <option value="Venezuela">Venezuela</option>
      <option value="Vietnam">Vietnam</option>
      <option value="Yemen">Yemen</option>
      <option value="Zambia">Zambia</option>     
      <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                          <label className="form-placeholder" htmlFor="country">Country</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-floating mb-3">
                        <select
  className="form-select"
  aria-label="Default select example"
  required
  value={selectedGender}
  onChange={(e) => setSelectedGender(e.target.value)}
>
  <option value="" disabled>Gender</option>
  <option value="Male">Male</option>
  <option value="Female">Female</option>
  <option value="Other">Other</option>
</select>

                          <label className="form-placeholder" htmlFor="gender">Gender</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className='form-floating mb-3'>
                          <input type="name" className="form-control" name="username" id="username" placeholder="Username" maxLength={20} required value={username} onChange={(e) => setUsername(e.target.value)} />
                          <label htmlFor="username" className="form-label">Username </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-floating mb-3">
                          <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} required />
                          <label htmlFor="email">Email</label>
                        </div>
                      </div>
                      <div className="col-12">
                          <div className="input-group mb-3">
                            <div className="form-floating">
                              <input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control"
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                              <label htmlFor="password">Password <span className="text-danger"></span></label>
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? 'Hide' : 'Show'}
                            </button>
                          </div>
                        </div>
  <div className='row'>
  {/* Left side - Checkboxes */}
  <div className="col-lg-6">
    <div className="row">
      <div className="col-12 form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="iAgree"
          checked={iAgree}
          onChange={() => setIAgree(!iAgree)}
          required
        />
        <label className="form-check-label" htmlFor="iAgree">
          I agree to the <a href="#!">Terms and Conditions</a>
        </label>
      </div>
      <div className="col-12 form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="ageVerified"
          checked={ageVerified}
          onChange={() => setAgeVerified(!ageVerified)}
          required
        />
        <label className="form-check-label" htmlFor="ageVerified">
          I am at least 18 years old
        </label>
      </div>
      <div className="col-12 form-check">
        <input
          type="checkbox"
          className="form-check-input"
          id="promoemail"
          checked={promoemail}
          onChange={() => setPromoEmail(!promoemail)}
          required
        />
        <label className="form-check-label" htmlFor="promoemail">
          I agree to receive promotional emails
        </label>
      </div>
    </div>
  </div>

  {/* Right side - Phone input */}
  <div className="col-lg-6">
    <div className="row justify-content-center mb-2">
      <PhoneInput
        country={'mt'} // Default country
        value={phoneNumber}
        onChange={setPhoneNumber}
        inputClassName="mx-auto"
        required
      />
    </div>
    <Link to="/login"><button className='btn btn-outline-success btn-sm'>Already have an account?</button></Link>

  </div>
  
</div>


                      <div className="col-12">
                        <button
                          className="btn btn-purple w-100 mb-2"
                          onClick={signup}
                          disabled={!isFormValid()}
                        >
                          <div className='text-light'>Sign Up</div>
                        </button>
                        {error && <p className="text-danger">{error}</p>}
                        {verificationSent && (
                        <div>
                          <div className="alert alert-success text-center" role="alert">
                            <p>Verification email sent. Please verify your email before signing in.</p>
                          </div>
                          <div className="alert alert-warning text-center" role="alert">
                            <p>Please refresh the page once you have done this</p>
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
);
};

export default SignUp;