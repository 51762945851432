import BayernMunich from '../Team-Kits-Images/BayernMunich.png'
import Leverkusen from '../Team-Kits-Images/Leverkusen.png'
import Freiburg from '../Team-Kits-Images/Freiburg.png'
import Frankfurt from '../Team-Kits-Images/Frankfurt.png'
import Berlin from '../Team-Kits-Images/Berlin.png'
import Leipzig from '../Team-Kits-Images/Leipzig.png'
import Stuttgart from '../Team-Kits-Images/Stuttgart.png'
import Dortmund from '../Team-Kits-Images/Dortmund.png'
import Heidenheim from '../Team-Kits-Images/Heidenheim.png'
import Mainz from '../Team-Kits-Images/Mainz.png'
import Bremen from '../Team-Kits-Images/Bremen.png'
import Augsburg from '../Team-Kits-Images/Augsburg.png'
import Wolfsburg from '../Team-Kits-Images/Wolfsburg.png'
import Monch from '../Team-Kits-Images/Monch.png'
import Hoffenheim from '../Team-Kits-Images/Hoffenheim.png'
import Bochum from '../Team-Kits-Images/Bochum.png'
import Pauli from '../Team-Kits-Images/Pauli.png'
import Kiel from '../Team-Kits-Images/Kiel.png'


import { useTranslation } from 'react-i18next';
import Standings from './Standings'
import { runTransaction } from 'firebase/firestore'


import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import BundesligaStandingsPicks from './BundesligaStandingsPicks'
import AdComponent from '../AdComponent'
 
function BundesligaPicks() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); // Initialize to the current round, e.g., round 8
  const [buttonsToShow, setButtonsToShow] = useState(0);

  const fetchTotalPoints = async (displayName) => {
    try {
      const db = getDatabase();
      const totalPointsRef = ref(db, `BundesligaTotalPoints/${displayName}`);
      const totalPointsSnapshot = await get(totalPointsRef);
      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  const fetchPicks = async (displayName) => {
    try {
      const db = getDatabase();
      const picksRef = ref(db, `BundesligaPicks/${displayName}`);
      const totalPointsRef = ref(db, `BundesligaTotalPoints/${displayName}`);

      const [picksSnapshot, totalPointsSnapshot] = await Promise.all([
        get(picksRef),
        get(totalPointsRef)
      ]);

      const picksData = picksSnapshot.val() || {};
      const userPicks = picksData || {};
      setInputsData(userPicks);
      setDisplayName(displayName);

      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);

      const pointsData = {};
      Object.keys(userPicks).forEach(fixtureId => {
        pointsData[fixtureId] = userPicks[fixtureId].points;
      });
      setFixturePoints(pointsData);

      // Start point incrementing intervals for the fetched picks
      Object.keys(userPicks).forEach(fixtureId => {
        if (!intervalRefs.current[fixtureId]) {
          startPointsIncrement(fixtureId);
        }
      });
    } catch (error) {
      console.error("Error fetching picks:", error);
    }
  };

  const [dateRanges] = useState([
    { start: '2024-06-11', end: '2024-08-26' },  // Matchweek 1
    { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 2
    { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 3
    { start: '2024-09-16', end: '2024-09-23' },  // Matchweek 4
    { start: '2024-09-23', end: '2024-09-30' },  // Matchweek 5
    { start: '2024-09-30', end: '2024-10-07' },  // Matchweek 6
    { start: '2024-10-07', end: '2024-10-21' },  // Matchweek 7
    { start: '2024-10-21', end: '2024-10-28' },  // Matchweek 8
    { start: '2024-10-28', end: '2024-11-04' },  // Matchweek 9
    { start: '2024-11-04', end: '2024-11-11' },  // Matchweek 10
    { start: '2024-11-11', end: '2024-11-25' },  // Matchweek 11
    { start: '2024-11-25', end: '2024-12-02' },  // Matchweek 12 // /// / / / / 
    { start: '2024-12-02', end: '2024-12-08' },  // Matchweek 13
    { start: '2024-12-08', end: '2024-12-15' },  // Matchweek 14
    { start: '2024-12-15', end: '2024-12-22' },  // Matchweek 15
    { start: '2024-12-22', end: '2025-01-12' },  // Matchweek 16
    { start: '2025-01-12', end: '2025-01-16' },  // Matchweek 17
    { start: '2025-01-16', end: '2025-01-19' },  // Matchweek 18 end of 2024
    { start: '2025-01-19', end: '2025-01-26' },  // Matchweek 19
    { start: '2025-01-26', end: '2025-02-02' },  // Matchweek 20
    { start: '2025-02-02', end: '2025-02-09' },  // Matchweek 21 // 
    { start: '2025-02-09', end: '2025-02-16' },  // Matchweek 22
    { start: '2025-02-16', end: '2025-02-23' },  // Matchweek 23
    { start: '2025-02-23', end: '2025-03-02' },  // Matchweek 24
    { start: '2025-03-02', end: '2025-03-09' },  // Matchweek 25
    { start: '2025-03-09', end: '2025-03-16' },  // Matchweek 26
    { start: '2025-03-16', end: '2025-03-30' },  // Matchweek 27
    { start: '2025-03-30', end: '2025-04-06' },  // Matchweek 28
    { start: '2025-04-06', end: '2025-04-13' },  // Matchweek 29
    { start: '2025-04-13', end: '2025-04-20' },  // Matchweek 30
    { start: '2025-04-20', end: '2025-04-27' },  // Matchweek 31
    { start: '2025-04-27', end: '2025-05-04' },  // Matchweek 32
    { start: '2025-05-04', end: '2025-05-11' },  // Matchweek 33
    { start: '2025-05-11', end: '2025-05-24' },  // Matchweek 34
    
        // Add more matchweeks as needed
    ]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);


  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    if (user) {
      const displayName = user.displayName || 'Anonymous';
      fetchPicks(displayName);
      fetchTotalPoints(displayName);
    }
  }, [user]);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/78/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  


useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);


const awardPointsForAllUsers = async (fixtureId) => {
  try {
    const db = getDatabase();
    const fixtureRef = ref(db, `fixtures/${fixtureId}`);
    const fixtureSnapshot = await get(fixtureRef);
    const fixtureData = fixtureSnapshot.val();

    console.log("Fixture Data:", fixtureData); // Debug: Log fixture data

    if (fixtureData && fixtureData.fixture.status.short === "FT") {
      const homeGoals = fixtureData.score.fulltime.home;
      const awayGoals = fixtureData.score.fulltime.away;

      const picksRef = ref(db, `BundesligaPicks`);
      const picksSnapshot = await get(picksRef);
      const allUserPicks = picksSnapshot.val() || {};

      Object.keys(allUserPicks).forEach(async (userId) => {
        const userPicks = allUserPicks[userId];
        const userPick = userPicks[fixtureId];

        if (userPick && !userPick.pointsAwarded) {
          const { home: predictedHomeGoals, away: predictedAwayGoals } = userPick;

          let points = 0;

          if (predictedHomeGoals == homeGoals && predictedAwayGoals == awayGoals) {
            points = 3;
          } else if (
            (predictedHomeGoals > predictedAwayGoals && homeGoals > awayGoals) ||
            (predictedHomeGoals < predictedAwayGoals && homeGoals < awayGoals) ||
            (predictedHomeGoals == predictedAwayGoals && homeGoals == awayGoals)
          ) {
            points = 1;
          }

          console.log(`Awarding ${points} points to user ${userId} for fixture ${fixtureId}`); // Debug: Log points awarded

          userPicks[fixtureId] = {
            ...userPick,
         
          };

          const userTotalPointsRef = ref(db, `BundesligaTotalPoints/${userId}`);
          const userTotalPointsSnapshot = await get(userTotalPointsRef);
          const userTotalPoints = userTotalPointsSnapshot.val() || { totalPoints: 0 };

          await set(userTotalPointsRef, {
            totalPoints: userTotalPoints.totalPoints + points,
          });

          await set(ref(db, `BundesligaPicks/${userId}`), userPicks);
        }
      });
    }
  } catch (error) {
    console.error("Error awarding points:", error);
  }
};



const startPointsIncrement = (fixtureId) => {
  intervalRefs.current[fixtureId] = setInterval(() => {
    awardPointsForAllUsers(fixtureId);
  }, 60000); // Check every 60 seconds
};

const stopPointsIncrement = (fixtureId) => {
  if (intervalRefs.current[fixtureId]) {
    clearInterval(intervalRefs.current[fixtureId]);
    delete intervalRefs.current[fixtureId];
  }
};

// Ensure that this function is called every 60 seconds to check for completed fixtures
useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);

const handleInputChange = (fixtureId, team, value) => {
    setInputsData(prevInputsData => ({
      ...prevInputsData,
      [fixtureId]: {
        ...prevInputsData[fixtureId],
        [team]: value,
  
      }
    }));
  };

  const handleSubmit = async () => {
    if (userId) {
      const db = getDatabase();
      const picksRef = ref(db, `BundesligaPicks/${displayName}`);
      const totalPointsRef = ref(db, `BundesligaTotalPoints/${displayName}`);
  
      let userDisplayName = user.displayName || 'Anonymous';
  
      // Extract user ID within parentheses
      const match = userDisplayName.match(/\(([^)]+)\)/);
      if (match && match[1]) {
        userDisplayName = match[1];
      }
  
      try {
        // Fetch existing picks from the backend
        const picksSnapshot = await get(picksRef);
        const existingPicksData = picksSnapshot.val() || {};
  
        // Debug: Log the current inputsData before filtering
        console.log("inputsData before filtering:", inputsData);
  
        // Filter inputsData to only include fixtures where a valid prediction is made
        const filteredInputsData = Object.keys(inputsData).reduce((acc, fixtureId) => {
          const fixturePrediction = inputsData[fixtureId];
  
          // Check if home or away prediction is made (0 is valid, undefined is not)
          if (fixturePrediction.home !== undefined || fixturePrediction.away !== undefined) {
            acc[fixtureId] = {
              home: fixturePrediction.home !== undefined ? fixturePrediction.home : "",
              away: fixturePrediction.away !== undefined ? fixturePrediction.away : ""
            };
          }
          return acc;
        }, {});
  
        // Debug: Log the filtered inputsData after filtering
        console.log("filteredInputsData:", filteredInputsData);
  
        // Check if any predictions are available for submission
        if (Object.keys(filteredInputsData).length === 0) {
          console.log("No predictions made, nothing to submit.");
          return;  // Exit early if there are no valid predictions
        }
  
        // Merge new filtered picks with existing picks
        const mergedPicks = {
          ...existingPicksData,
          ...filteredInputsData
        };
  
        // Debug: Log the merged data being sent to the backend
        console.log("Merged picks data being submitted:", mergedPicks);
  
        // Submit the merged picks to the backend
        await set(picksRef, mergedPicks);
  
        // Check if totalPoints entry exists, if not, initialize it
        const totalPointsSnapshot = await get(totalPointsRef);
        if (!totalPointsSnapshot.exists()) {
          await set(totalPointsRef, { totalPoints: 0 });
        }
  
        setSent(true);
        setTimeout(() => setSent(false), 15000);
        setConfetti(true);
        setTimeout(() => setConfetti(false), 5000);
  
        // Start points incrementing for all fixtures with a prediction
        Object.keys(filteredInputsData).forEach(fixtureId => {
          startPointsIncrement(fixtureId);
        });
      } catch (error) {
        console.error('Error submitting picks:', error);
        setError(true);
        setTimeout(() => setError(false), 5000);
      }
    }
  };
  

  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamLogos = {
    'Bayern München': BayernMunich,
    'Bayer Leverkusen': Leverkusen,
    'SC Freiburg': Freiburg,
    'Eintracht Frankfurt': Frankfurt,
    'Union Berlin': Berlin,
    'RB Leipzig': Leipzig,
    'VfB Stuttgart': Stuttgart,
    'Borussia Dortmund': Dortmund,
    '1. FC Heidenheim': Heidenheim,
    'FSV Mainz 05': Mainz,
    'Werder Bremen': Bremen,
    'FC Augsburg': Augsburg,
    'VfL Wolfsburg': Wolfsburg,
    'Borussia Mönchengladbach': Monch,
    '1899 Hoffenheim': Hoffenheim,
    'VfL Bochum': Bochum,
    'FC St. Pauli': Pauli,
    'Holstein Kiel': Kiel


   
  };
  
  const teamInitials = {
    'Bayern München': 'FCB',
    'Bayer Leverkusen': 'B04',
    'SC Freiburg': 'SCF',
    'Eintracht Frankfurt': 'EFK',
    'Union Berlin': 'FCU',
    'RB Leipzig': 'RBL',
    'VfB Stuttgart': 'VFB',
    'Borussia Dortmund': 'BVB',
    '1. FC Heidenheim': 'HEI',
    'FSV Mainz 05': 'M05',
    'Werder Bremen': 'SVW',
    'FC Augsburg': 'FCA',
    'VfL Wolfsburg': 'WOB',
    'Borussia Mönchengladbach': 'BMG',
    '1899 Hoffenheim': 'TSG',
    'VfL Bochum': 'BOC',
    'FC St. Pauli': 'FCSP',
    'Holstein Kiel': 'KIE'

   
  };

  const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };
  
  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };
  const stadiums = {
    'Bayern München': 'Stadium: Allianz Arena',
    'Bayer Leverkusen': 'Stadium: BayArena',
    'SC Freiburg': 'Stadium: Europa-Park Stadion',
    'Eintracht Frankfurt': 'Stadium: Deutsche Bank Park',
    'Union Berlin': 'Stadium: Stadion An der Alten Försterei',
    'RB Leipzig': 'Stadium: Red Bull Arena',
    'VfB Stuttgart': 'Stadium: Mercedes-Benz Arena',
    'Borussia Dortmund': 'Stadium: Signal Iduna Park',
    '1. FC Heidenheim': 'Stadium: Voith-Arena',
    'FSV Mainz 05': 'Stadium: MEWA Arena',
    'Werder Bremen': 'Stadium: Weserstadion',
    'FC Augsburg': 'Stadium: WWK Arena',
    'VfL Wolfsburg': 'Stadium: Volkswagen Arena',
    'Borussia Mönchengladbach': 'Stadium: Borussia-Park',
    '1899 Hoffenheim': 'Stadium: PreZero Arena',
    'VfL Bochum': 'Stadium: Vonovia Ruhrstadion',
    'FC St. Pauli': 'Stadium: Millerntor-Stadion',
    'Holstein Kiel': 'Stadium: Holstein-Stadion',
  };
  
  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

  
  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(`Round ${index + 1}`);
    handlePageChange(index + 1);
  };

  // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  useEffect(() => {
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonsToShow = Math.floor(containerWidth / 125); // Adjust this value based on your design
        setButtonsToShow(Math.min(8, calculatedButtonsToShow));
        calculateStartIndex(currentPage, Math.min(8, calculatedButtonsToShow));
      }
    };

    calculateButtonsToShow();

    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, [currentPage]);

  const calculateStartIndex = (currentPage, buttonsToShow) => {
    let minIndex = Math.max(0, currentPage - Math.floor(buttonsToShow / 2));
    minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
    setStartIndex(minIndex);
  };

  const handleMatchdaySelectSmooth = (index) => {
    setSelectedMatchdayIndex(index);
    handleMatchdaySelect(index);
  };

  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -((100 / buttonsToShow) + 10), // Move left by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + buttonsToShow < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: (100 / buttonsToShow) + 10, // Move right by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex + 1);
    }
  };


  return (
    <div>
    <Navbar />
    <div className='App-headerV8'>
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif'}}>Bundesliga Picks</h2>
      <div className="text-light text-center" style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
  {/* Mobile Dropdown */}
  <div className="dropdown d-inline-block d-md-none">
    <button className="btn btn-purple dropdown-toggle" type="button" id="groupStageDropdown" data-bs-toggle="dropdown" aria-expanded="true">
      {selectedMatchday}
    </button>
    <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
      <ul className="list-group">
        {dateRanges.map((range, index) => (
          <li key={index} className="list-group-item">
            <button
              className={`dropdown-item ${selectedMatchday === `Round ${index + 1}` ? 'btn-purple text-light' : ''}`}
              onClick={() => handleMatchdaySelect(index)}
              style={{ width: '100%' }}
            >
              Round {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>

  {/* Horizontal Scroll for Large Screens */}
  <div className="container">
    <div className="d-none d-md-flex align-items-center justify-content-center" style={{ width: '100%', marginLeft: '3rem' }} ref={containerRef}>
      <button className="btn btn-light" onClick={handleLeftArrowClick} disabled={startIndex === 0} style={{ width: '5%', marginRight: '1rem' }}>
        &lt;
      </button>

      <div
        className="rounds-container"
        ref={roundsContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '70%', // Set to allow space for buttons
          marginLeft: '1rem',
          scrollBehavior: 'smooth',
        }}
      >
        {dateRanges.slice(startIndex, startIndex + buttonsToShow).map((range, index) => (
          <button
            key={startIndex + index}
            className={`btn me-2 ${selectedMatchday === `Round ${startIndex + index + 1}` ? 'btn-purple' : 'btn-light'}`}
            style={{ width: `calc(${(100 / buttonsToShow)}% - 9px)` }}
            onClick={() => handleMatchdaySelect(startIndex + index)}
          >
            Round {startIndex + index + 1}
          </button>
        ))}
      </div>

      <button className="btn btn-light" onClick={handleRightArrowClick} disabled={startIndex + buttonsToShow >= dateRanges.length} style={{ width: '5%', marginLeft: '1rem' }}>
        &gt;
      </button>
    </div>
  </div>
</div>
 
      <div className='container'>
        <div className='row'>
           {/* Standings Card for large screens */}
           <div className='col-lg-5 d-none d-lg-flex align-items-start justify-content-end standings-card-margin' style={{ paddingTop: '15px' }}>
  <div className='card' style={{ backgroundColor: 'transparent', border: 'none' }}> {/* Makes the card background and border transparent */}
    <div className='card-body'>
      <h5 className='card-title'><BundesligaStandingsPicks /></h5>
    </div>
  </div>
</div>
  
          {/* Main content area */}
          <div className='col-lg-7 col-12'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
  
              let points = 0;
  
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
  
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-0'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
      <h6 className='card-title mb-3'>{stadiums[fixture.teams.home.name]}</h6>
    )}
              
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                    <div className="col text-center padtopinput border-secondary">
  <div className='form-group text-center' style={{ borderColor: '#9370DB' }}>
    <select
      value={inputsData[fixture.fixture.id]?.home ?? ''}
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'home', value);
        } else {
          handleInputChange(fixture.fixture.id, 'home', '');
        }
      }}
      className='form-control text-center'
      disabled={fixture.fixture.status.short !== 'NS'}
      style={{ 
        textAlign: 'center', 
        textAlignLast: 'center',
        borderColor: '#9370DB' // Apply border color here
      }}
    >
      <option value="">Pick</option>
      {[...Array(11).keys()].map(num => (
        <option key={num} value={num}>{num}</option>
      ))}
    </select>
  </div>
</div>
                    <div className="col text-center padtopinput border-secondary">
  <div className='form-group text-center' style={{ borderColor: '#9370DB' }}>
    <select
      value={inputsData[fixture.fixture.id]?.away ?? ''}
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'away', value);
        } else {
          handleInputChange(fixture.fixture.id, 'away', '');
        }
      }}
      className='form-control text-center'
      disabled={fixture.fixture.status.short !== 'NS'}
      style={{ 
        textAlign: 'center', 
        textAlignLast: 'center',
        borderColor: '#9370DB' // Apply border color here
      }}
    >
      <option value="">Pick</option>
      {[...Array(11).keys()].map(num => (
        <option key={num} value={num}>{num}</option>
      ))}
    </select>
  </div>
</div>

                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
                  <div>Result: {fixture.fixture.status.short === 'NS' ? 'TBA' : `${fixture.goals.home} - ${fixture.goals.away}`}</div>
              
                  {/* Display the points scored for this fixture */}
                  {fixture.fixture.status.short === 'FT' && (
                    <p className={points === 1 ? 'text-warning' : points === 3 ? 'text-success' : ''}>
                      You scored <b>{points}</b> {points === 1 ? 'point' : 'points'} for this game
                    </p>
                  )}
                </div>
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
 style={{ 
   backgroundColor: '#9370DB', 
   borderRadius: '5px', 
   borderBottomLeftRadius: '0px',  // Set bottom-left corner radius to 0
   borderBottomRightRadius: '0px'  // Set bottom-right corner radius to 0
 }}
>
<p className="text-center mb-2 btn-sm text-bold" 
 style={{ 
   color: '#fff', 
   paddingTop: '4px',  
   borderTopLeftRadius: '1px' 
 }}
>
Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}
</p>
</div>
              </div>
              
              
              );
            })}
  
            <div className='text-center' style={{ paddingBottom: '10px' }}>
              <button onClick={handleSubmit} className='btn btn-purple text-center'>Submit Picks</button>
              {confetti && <Confetti />}
            </div>
  
            <div className='container text-center padtop' style={{ paddingBottom: '10px' }}>
              <p className='text-light'>Total points: <b>{totalPoints}</b></p>
            </div>
  
            {sent && (
              <>
                <div className='alert alert-success Nav-HeaderV8 text-center'>
                  Your picks have been sent!
                </div>
                {confetti && <Confetti gravity={-5.5} />}
              </>
            )}
            {error && <p className='text-danger'>Error submitting picks!</p>}
          </div>

          <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
  <AdComponent />
  </div>
  
             {/* Standings Card for small screens */}
             <div className='col-12 d-lg-none mb-3' >
            <div className='card' style={{ backgroundColor: 'transparent', border: 'none' }}>
              <div className='card-body'>
                <h5 className='card-title'><BundesligaStandingsPicks /></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  
  );
}

export default BundesligaPicks;
