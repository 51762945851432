import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getDatabase, ref, update, get } from 'firebase/database'; // Import for Firebase Realtime Database
import { Link } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const AuthDetails = ({ navigate }) => {
  const [authUser, setAuthUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const db = getDatabase();
        const userRef = ref(db, `users/${user.uid}`);
        get(userRef).then(snapshot => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            // Bypass inactivity check and allow login for all users
            if (userData) {
              setAuthUser(user); // Set the user as authenticated regardless of status
            }
          }
        });
      } else {
        setAuthUser(null); // Clear user data if not authenticated
      }
    });

    return () => unsubscribe();
  }, []);

  const userSignOut = async () => {
    try {
      if (authUser) {
        const db = getDatabase();
        const userRef = ref(db, `users/${authUser.uid}`);

        // Optionally update user status to inactive here if you want to track user activity
        await update(userRef, { userStatus: 'inactive' });
      }

      await signOut(auth);
      console.log('signed out');
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };

  return (
    <div>
      {authUser ? (
        <div style={{ display: 'flex', gap: '10px' }}>
          {authUser.emailVerified ? (
            <>
              <div className="p-0">
                <div className="dropdown">
                  <button
                    className="btn btn-outline-light text-center dropdown-toggle p-0"
                    type="button"
                    id="dropdownMenuButton"
                    onClick={toggleDropdown}
                  >
                    <div className="btn btn-purple">
                      <FaUser />
                    </div>
                  </button>
                  <ul
                    className={`dropdown-menu ${dropdownOpen ? 'show' : ''} p-0`}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <Link
                        to="/userprofile"
                        className="dropdown-item custom-bg-purple"
                        onClick={toggleDropdown}
                      >
                        {t('profile')}
                      </Link>
                    </li>
                    <li>
                      <button
                        className="dropdown-item custom-bg-purple"
                        onClick={userSignOut}
                      >
                        {t('logout')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <div style={{ display: 'flex', gap: '10px' }}></div>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Link to="/login">
            <button className="btn btn-purple">{t('login')}</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default AuthDetails;
