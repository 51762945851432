import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, NavLink } from 'react-router-dom';
import AuthDetails from './auth/AuthDetails';
import { FaSearch } from 'react-icons/fa';
import { getDatabase, onValue } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { ref } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import Logo from './Favicon.png';
import WhiteLogo from './LogoWhite3.png';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'

function Navbar() {
  const [bracketData, setBracketData] = useState({});
  const [bracketIdSuggestions, setBracketIdSuggestions] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng); // Set the language state to the selected language
  };

  const [language, setLanguage] = useState('en'); // Default language is English
  
  return (
    <div className='Nav-headerV8'>
    <nav className='navbar navbar-expand-lg navbar-light bg-light border-bottom border-dark-subtle'>
      <div className='container-fluid'>
        <Link to='/' className='navbar-brand' href='#' style={{ color: '' }}>
          {/* Interstellar Predictions */}
          <img src={WhiteLogo} alt="" width='120' height="35"/>
          {/* Interstellar Predictions */}
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' style={{ color: 'black' }}></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item text-dark navhover'>
              <NavLink to='/news' className='nav-link text-dark navhover' href='about'>
                {t('news')}
              </NavLink>
            </li>
            <li className='nav-item text-dark'>
              <NavLink to='/social' className='nav-link text-dark navhover' href='news'>
                {t('social')}
              </NavLink>
            </li>
          </ul>
  
          {/* Search Bar */}
          <form className="d-flex me-3">
            <input 
              className="form-control me-2" 
              type="search" 
              placeholder="Search" 
              aria-label="Search" 
            />
    
          </form>
  
          <AuthDetails /> 
        </div>
      </div>
    </nav>
  </div>
  
  );
}

export default Navbar;
