import React, { useEffect, useState } from 'react';
import { getDatabase, ref, set, push, update, ref as dbRef, get  } from 'firebase/database';
import Navbar from './Navbar';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase';
import { Line } from 'react-chartjs-2';
import './Home.css'
import { Spinner } from 'react-bootstrap';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Home = () => {
  const [fixture, setFixture] = useState(null); // To store fixture data
  const [userBet, setUserBet] = useState(null); // Store user's selected bet
  const [betId, setBetId] = useState(null); // Store bet ID
  const [betAmount, setBetAmount] = useState(0); // Store bet amount
  const [successMessage, setSuccessMessage] = useState('');
  const [prizePot, setPrizePot] = useState(0); // Store the prize pot (total money bet)
  const [user, setUser] = useState(null); // Store current user data (uid, username)
  const [userBets, setUserBets] = useState([]); // Store list of users and their bets
  const [tokens, setTokens] = useState(0);
  const [activeTab, setActiveTab] = useState(null); // Track the currently active tab
  const [showChart, setShowChart] = useState(false); // Show/hide chart
  const [showOrders, setShowOrders] = useState(false); // Show/hide orders
  const [isModalOpen, setIsModalOpen] = useState(false);
const [fixtures, setFixtures] = useState([]); // Initialize fixtures as an empty array

  const [odds, setOdds] = useState({
    teamA: 0.33,
    draw: 0.33,
    teamB: 0.33,
  }); // Store odds for each outcome


// Update your chartData
const chartData = {
  labels: [fixture?.teams?.home?.name || "Loading...", 'Draw', fixture?.teams?.away?.name || "Loading..."],
  datasets: [
    {
      label: 'Graph',
      data: [
        fixture?.teams?.home ? (odds.teamA * 100) : 0,
        fixture?.teams?.home && fixture?.teams?.away ? (odds.draw * 100) : 0,
        fixture?.teams?.away ? (odds.teamB * 100) : 0,
      ],
      backgroundColor: [
        'rgba(75, 192, 192, 0.2)', 
        'rgba(255, 159, 64, 0.2)', 
        'rgba(153, 102, 255, 0.2)',
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)', 
        'rgba(255, 159, 64, 1)', 
        'rgba(153, 102, 255, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const chartOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        // Adding percentage symbol to tooltips
        label: function(tooltipItem) {
          return tooltipItem.raw.toFixed(2) + '%';  // Add percentage sign
        },
      },
    },
  },
};

  useEffect(() => {
    const auth = getAuth();
  
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const db = getDatabase();
          const userRef = ref(db, `users/${currentUser.uid}`);
          const snapshot = await get(userRef);
  
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUser({
              uid: currentUser.uid,
              username: extractUsernameFromDisplayName(currentUser.displayName) || "No Username",
              tokens: userData.tokens || 0, // Fetch and store tokens
            });
            setTokens(userData.tokens || 0); // Separate state for tokens
          } else {
            console.error("User data not found in database");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setUser(null);
        setTokens(0); // Reset tokens on logout
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  useEffect(() => {
    const fetchUserBets = async () => {
      if (!fixture) return; // Ensure the fixture is loaded
  
      try {
        const db = getDatabase();
        const betsRef = ref(db, `bets`);
        const snapshot = await get(betsRef);
  
        if (snapshot.exists()) {
          const betsData = snapshot.val();
  
          // Filter bets for the current fixture
          const currentFixtureBets = Object.values(betsData).filter(
            (bet) => bet.fixtureId === fixture.fixture.id
          );
  
          setUserBets(currentFixtureBets); // Store bets specific to the current fixture
        } else {
          setUserBets([]); // No bets found for this fixture
        }
      } catch (error) {
        console.error("Error fetching bets:", error);
        setUserBets([]);
      }
    };
  
    fetchUserBets();
  }, [fixture]); // Re-fetch bets whenever the fixture changes
  
  
  // Fetch the next fixture and its odds from Firebase
  useEffect(() => {
    const fetchNextFixture = async () => {
      try {
        const response = await fetch(
          "https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2024",
          {
            headers: {
              'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg==', // Replace with your API key
            },
          }
        );
        const data = await response.json();
        const upcomingFixtures = data.filter(
          (match) => match.fixture.status.short !== "FT"
        );
        const nextFixture = upcomingFixtures[0];
  
        setFixture(nextFixture);
  
        // Fetch odds and prize pot from Firebase
        const db = getDatabase();
        const fixtureRef = ref(db, `fixtures/${nextFixture.fixture.id}`);
        const snapshot = await get(fixtureRef);
  
        if (!snapshot.exists()) {
          // Add the fixture and initial odds if not present in Firebase
          const newFixture = {
            homeTeam: nextFixture.teams.home.name,
            awayTeam: nextFixture.teams.away.name,
            odds: { teamA: 0.33, draw: 0.33, teamB: 0.33 },
            prizePot: 0, // Initial prize pot is 0
          };
  
          await set(fixtureRef, newFixture);
          setOdds(newFixture.odds);
          setPrizePot(newFixture.prizePot); // Set initial prize pot
          console.log("Fixture added to Firebase:", newFixture);
        } else {
          // Load the odds and prize pot from Firebase
          const fixtureData = snapshot.val();
          setOdds(fixtureData.odds);
          setPrizePot(fixtureData.prizePot || 0); // Set the prize pot from Firebase
        }
      } catch (error) {
        console.error("Error fetching fixture data:", error);
      }
    };
  
    fetchNextFixture();
  }, []); // Empty array ensures this runs only once when the component mounts

 

  const handleBetAmountChange = (amount) => {
    setBetAmount(amount);
  };
  
  const adjustOdds = (selectedBet, amount) => {
    const totalAdjustment = 0.01 * (amount / 10); // Adjustment based on bet amount
    const adjustedOdds = { ...odds }; // Create a copy of current odds
  
    // Adjust odds for the selected bet
    if (selectedBet === fixture.teams.home.name) {
      adjustedOdds.teamA += totalAdjustment;
      adjustedOdds.teamB -= totalAdjustment / 2;
      adjustedOdds.draw -= totalAdjustment / 2;
    } else if (selectedBet === fixture.teams.away.name) {
      adjustedOdds.teamB += totalAdjustment;
      adjustedOdds.teamA -= totalAdjustment / 2;
      adjustedOdds.draw -= totalAdjustment / 2;
    } else if (selectedBet === "Draw") {
      adjustedOdds.draw += totalAdjustment;
      adjustedOdds.teamA -= totalAdjustment / 2;
      adjustedOdds.teamB -= totalAdjustment / 2;
    }
  
    // Ensure odds for all outcomes remain valid (e.g., >= 0.1)
    Object.keys(adjustedOdds).forEach((key) => {
      if (adjustedOdds[key] < 0.1) {
        adjustedOdds[key] = 0.1;
      }
    });
  
    console.log("Odds adjusted:", adjustedOdds); // Debugging log
    return adjustedOdds;
  };

  const handleBet = async (selectedBet) => {
    if (!fixture || !betAmount || betAmount <= 0) {
      console.error("Invalid bet or fixture not loaded!");
      return;
    }
  
    if (!user) {
      alert("Please log in to place a bet.");
      return;
    }
  
    if (tokens < betAmount) {
      alert("Insufficient tokens to place this bet.");
      return;
    }
  
    const updatedOdds = adjustOdds(selectedBet, betAmount);
  
    const bet = {
      fixtureId: fixture.fixture.id,
      homeTeam: fixture.teams.home.name,
      awayTeam: fixture.teams.away.name,
      userPick: selectedBet,
      userId: user.uid,
      username: user.username,
      datePlaced: new Date().toISOString(),
      odds: { ...odds },
      betAmount,
    };
  
    try {
      const db = getDatabase();
  
      // Save the bet to Firebase
      const betRef = push(ref(db, "bets"));
      await set(betRef, bet);
  
      // Deduct tokens from the user's balance
      const userRef = ref(db, `users/${user.uid}`);
      const newTokenBalance = tokens - betAmount;
      await update(userRef, { tokens: newTokenBalance });
  
      // Update the odds and prize pot in Firebase
      const fixtureRef = ref(db, `fixtures/${fixture.fixture.id}`);
      await update(fixtureRef, { odds: updatedOdds });
  
      const fixtureSnapshot = await get(fixtureRef);
      const existingPrizePot = fixtureSnapshot.exists() ? fixtureSnapshot.val().prizePot || 0 : 0;
      const newPrizePot = existingPrizePot + betAmount;
      await update(fixtureRef, { prizePot: newPrizePot });
  
      setOdds(updatedOdds);
      setPrizePot(newPrizePot);
      setTokens(newTokenBalance); // Update token state
      setSuccessMessage(`Your bet on ${selectedBet} has been placed successfully!`);
      setTimeout(() => setSuccessMessage(''), 5000);
  
      console.log("Bet successfully placed and odds updated:", bet);
      alert(`Your bet on ${selectedBet} has been placed!`);
    } catch (error) {
      console.error("Error placing bet:", error);
      alert("An error occurred while placing your bet. Please try again.");
    }
  };

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const extractUsernameFromDisplayName = (displayName) => {
    if (!displayName) {
      return ''; // Handle the case where displayName is null or undefined
    }
  
    // Using regular expression to extract content inside the first set of parentheses
    const match = displayName.match(/\(([^)]+)\)/);
  
    // Check if there is a match and return the content inside the first set of parentheses
    return match ? match[1] : displayName;
  };

  // Display loading message if the fixture is not yet available
  if (!fixture) {
    return <div className='custom-page .dark '>   <h2 className='text-center'><Spinner /></h2></div>;
  }

  // Toggle chart visibility when the user clicks on "Graph"
  const handleGraphClick = () => {
    if (activeTab === 'graph') {
      // If already active, toggle off
      setActiveTab(null);
      setShowChart(false);
    } else {
      // Otherwise, activate
      setActiveTab('graph');
      setShowChart(true);
      setShowOrders(false); // Ensure Orders is hidden
    }
  };
  
  // Handler for Orders tab toggle
  const handleOrdersClick = () => {
    if (activeTab === 'orders') {
      // If already active, toggle off
      setActiveTab(null);
      setShowOrders(false);
    } else {
      // Otherwise, activate
      setActiveTab('orders');
      setShowOrders(true);
      setShowChart(false); // Ensure Graph is hidden
    }
  };


  return (
    <div>
    <Navbar />
    <div className="full-page">
    <div className="custom-page">
  <section className="dark">
    <div className="container py-4">
      <div className="row">
            {/* col-2 card */}
<div className="col-2 d-none d-lg-block">
  <article className="postcard dark blue">
    <div className="postcard__text">
      <h3>Sidebar</h3>
    </div>
  </article>
</div>
        {/* Larger card */}
        <div className="col-lg-6 col-sm-12">          
          <article className="postcard dark blue">
            <div className="postcard__text">
              <h1 className="postcard__title blue">
                <a href="">
                  {fixture.teams.home.name} vs {fixture.teams.away.name} 
                </a>
              </h1>
              <div className="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                {/* <i className="fas fa-calendar-alt mr-2">{convertToFormattedDateTime(fixture.fixture.date)}</i> <br /> */}
                  <i className="fas fa-calendar-alt mr-2"></i>Vol: {prizePot.toFixed(2)} Tokens -                    <span class="badge bg-dark">                {convertToFormattedDateTime(fixture.fixture.date)} </span>

                </time>
              </div>
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
                {/* Content for the larger card */}
              </div>
              <div className="odds-section">
  <div className="team-names">
    <div className="home-team-name">{fixture.teams.home.name}</div>
    <div className="draw-team-name">Draw</div>
    <div className="away-team-name">{fixture.teams.away.name}</div>
  </div>
  <div className="bet-buttons">
  <button
    onClick={() => {
      setUserBet(fixture.teams.home.name);
      setIsModalOpen(true);
    }}
    className={`bet-button team-a ${userBet === fixture.teams.home.name ? "active" : ""}`}
  >
    {odds.teamA.toFixed(2)}
  </button>
  <button
    onClick={() => {
      setUserBet("Draw");
      setIsModalOpen(true);
    }}
    className={`bet-button draw ${userBet === "Draw" ? "active" : ""}`}
  >
    {odds.draw.toFixed(2)}
  </button>
  <button
    onClick={() => {
      setUserBet(fixture.teams.away.name);
      setIsModalOpen(true);
    }}
    className={`bet-button team-b ${userBet === fixture.teams.away.name ? "active" : ""}`}
  >
    {odds.teamB.toFixed(2)}
  </button>
</div>
</div>

              <ul className="postcard__tagbox mb-3">
  <li className="tag__item">
    <i className="fas fa-tag mr-2"></i>
    <span
      onClick={handleGraphClick}
      style={{ cursor: 'pointer' }}
      className={activeTab === 'graph' ? 'active' : ''}
    >
      Graph
    </span>
  </li>
  <li className="tag__item">
    <i className="fas fa-clock mr-2"></i>
    <span
      onClick={handleOrdersClick}
      style={{ cursor: 'pointer' }}
      className={activeTab === 'orders' ? 'active' : ''}
    >
      Orders
    </span>
  </li>
</ul>
              {/* Conditionally render the chart based on state */}
              {showOrders && (
   <div className="user-bet-list">
   <h5 className="text-light">Users Who Have Made Predictions</h5>
   <hr className="w-90" />
   <div className={`bet-list-container ${userBets.length > 5 ? "scrollable" : ""}`}>
     <ul className="text-center text-light">
       {userBets.length > 0 ? (
         userBets.map((bet, index) => (
           <li key={index} className="bet-item">
             <strong>{bet.username}</strong> bet <span className="bet-amount">{bet.betAmount}</span> tokens on <span className="bet-pick">{bet.userPick}</span>
           </li>
         ))
       ) : (
         <li>No users have placed bets for this match yet.</li>
       )}
     </ul>
   </div>
 </div>
 
           
              )}
            </div>
          </article>
          {showChart && (
            <div className="chart-container">
              <Line data={chartData} options={chartOptions} />
            </div>
          )}
        </div>
        
        {/* Smaller card */}
<div className="col-4 d-none d-lg-block">
  <article className="postcard dark blue">
    <div className="postcard__text">
      <h1 className="postcard__title blue">
      <a href="#">
  {userBet 
    ? userBet === "Draw" 
      ? "Draw"  // If the user has selected Draw, show "Draw"
      : `${userBet} Win`  // If the user has selected any team, show "Team Win"
    : "Place Your Bet"  // Default text if no bet is placed
  }
</a>
      </h1>
      {/* Odds Button */}
      {userBet && (
        <div className="odds-section">
          <button className="bet-odds-button full-width">
            {userBet === fixture.teams.home.name
              ? ` ${odds.teamA.toFixed(2)}`
              : userBet === "Draw"
              ? ` ${odds.draw.toFixed(2)}`
              : ` ${odds.teamB.toFixed(2)}`}
          </button>
        </div>
      )}
<div className="bet-input-section">
  <h4 className="text-light">Amount</h4>

  {/* Container for input and buttons */}
  <div className="bet-amount-container">


    {/* Bet Amount Input */}
    <input
      type="number"
      value={betAmount || ""}
      onChange={(e) => handleBetAmountChange(Math.max(1, Number(e.target.value)))}
      placeholder="Enter Tokens"
      className="bet-input-field text-center"
      onWheel={(e) => e.preventDefault()}  // Disable scroll behavior
    />


  </div>
</div>


      {userBet && betAmount > 0 && (
        <div className="returns-card">
          <h3 className="returns-title">Potential Returns</h3>
          <div className="returns-list">
            {userBet === fixture.teams.home.name && (
              <p>
                {(betAmount * (1 / odds.teamA)).toFixed(2)} Tokens
                <br />
                Profit: {(betAmount * (1 / odds.teamA) - betAmount).toFixed(2)} Tokens{" "}
                ({(((betAmount * (1 / odds.teamA) - betAmount) / betAmount) * 100).toFixed(2)}%)
              </p>
            )}
            {userBet === "Draw" && (
              <p>
                {(betAmount * (1 / odds.draw)).toFixed(2)} Tokens
                <br />
                Profit: {(betAmount * (1 / odds.draw) - betAmount).toFixed(2)} Tokens{" "}
                ({(((betAmount * (1 / odds.draw) - betAmount) / betAmount) * 100).toFixed(2)}%)
              </p>
            )}
            {userBet === fixture.teams.away.name && (
              <p>
                {(betAmount * (1 / odds.teamB)).toFixed(2)} Tokens
                <br />
                Profit: {(betAmount * (1 / odds.teamB) - betAmount).toFixed(2)} Tokens{" "}
                ({(((betAmount * (1 / odds.teamB) - betAmount) / betAmount) * 100).toFixed(2)}%)
              </p>
            )}
          </div>
        </div>
      )}
      {betAmount > 0 && userBet && (
        <button
          className="confirm-bet-button full-width"
          onClick={() => handleBet(userBet)}
        >
          Confirm
        </button>
      )}
    </div>
  </article>
</div>

 {/* Modal for smaller screens */}
<div className="d-block d-lg-none">
{/* Modal for small screens */}
{isModalOpen && (
  <div className="modal-overlay">
    <div className="modal-content">
      <div className="postcard dark blue">
        <div className="postcard__text">
          <h1 className="postcard__title blue">
            {userBet ? `${userBet} Win` : "Place Your Bet"}
          </h1>

          {/* Odds Button */}
          {userBet && (
            <div className="odds-section">
              <button className="bet-odds-button full-width">
                {userBet === fixture.teams.home.name
                  ? ` ${odds.teamA.toFixed(2)}`
                  : userBet === "Draw"
                  ? ` ${odds.draw.toFixed(2)}`
                  : ` ${odds.teamB.toFixed(2)}`}
              </button>
            </div>
          )}

          {/* Bet Amount Input */}
          <div className="bet-input-section">
            <h4 className="text-light">Amount</h4>
            <input
              type="number"
              value={betAmount || ""}
              onChange={(e) => handleBetAmountChange(Number(e.target.value))}
              placeholder="Enter Tokens"
              className="bet-input-field text-center"
            />
          </div>

          {/* Potential Returns */}
          {userBet && betAmount > 0 && (
            <div className="returns-card">
              <h3 className="returns-title">Potential Returns</h3>
              <div className="returns-list">
                {userBet === fixture.teams.home.name && (
                  <p>
                    {(betAmount * (1 / odds.teamA)).toFixed(2)} Tokens
                    <br />
                    Profit: {(betAmount * (1 / odds.teamA) - betAmount).toFixed(2)} Tokens{" "}
                    ({(((betAmount * (1 / odds.teamA) - betAmount) / betAmount) * 100).toFixed(2)}%)
                  </p>
                )}
                {userBet === "Draw" && (
                  <p>
                    {(betAmount * (1 / odds.draw)).toFixed(2)} Tokens
                    <br />
                    Profit: {(betAmount * (1 / odds.draw) - betAmount).toFixed(2)} Tokens{" "}
                    ({(((betAmount * (1 / odds.draw) - betAmount) / betAmount) * 100).toFixed(2)}%)
                  </p>
                )}
                {userBet === fixture.teams.away.name && (
                  <p>
                    {(betAmount * (1 / odds.teamB)).toFixed(2)} Tokens
                    <br />
                    Profit: {(betAmount * (1 / odds.teamB) - betAmount).toFixed(2)} Tokens{" "}
                    ({(((betAmount * (1 / odds.teamB) - betAmount) / betAmount) * 100).toFixed(2)}%)
                  </p>
                )}
              </div>
            </div>
          )}

          {/* Confirm Button */}
          {betAmount > 0 && userBet && (
            <button
              className="confirm-bet-button full-width"
              onClick={() => {
                handleBet(userBet);
                setIsModalOpen(false);
              }}
            >
              Confirm
            </button>
          )}

          {/* Close Button */}
          <button
            className="close-modal-button"
            onClick={() => setIsModalOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}

</div>

      </div>
    </div>
  </section>
</div>
  </div>
  </div>
  );
};

export default Home;
