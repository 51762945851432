import React, { useState, useEffect } from "react";
import { getDatabase, ref, set, get, update, db } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function FootballFixtures() {
  const [fixtures, setFixtures] = useState([]);
  const [odds, setOdds] = useState({});
  const [prizePots, setPrizePots] = useState({});
  const [userTokens, setUserTokens] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [betAmount, setBetAmount] = useState(""); // Track the bet amount input by user
  const [selectedFixture, setSelectedFixture] = useState(null);
  const [selectedPick, setSelectedPick] = useState(""); // Track the selected outcome (home, draw, away)
  const [potentialWinnings, setPotentialWinnings] = useState(0);
  // Listen to auth state changes and fetch user tokens
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        try {
          const db = getDatabase();
          const userRef = ref(db, `users/${user.uid}`);
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setUserTokens(userData.tokens || 0);  // Set user tokens
          }
        } catch (error) {
          console.error("Error fetching user tokens:", error);
        }
      } else {
        setCurrentUser(null);
        setUserTokens(0);
      }
    });

    return () => unsubscribe(); // Cleanup function to unsubscribe from auth state changes
  }, []); // Empty dependency array to run the effect once when the component mounts
  
  // Fetch upcoming fixtures
  useEffect(() => {
    const fetchUpcomingFixtures = async () => {
      try {
        const response = await fetch(
          "https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2024",
          {
            headers: {
              "x-functions-key":
                "HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg==",
            },
          }
        );
        const data = await response.json();
        const upcomingFixtures = data.filter(
          (match) => match.fixture.status.short !== "FT"
        );

        const nextTenFixtures = upcomingFixtures.slice(0, 10);
        setFixtures(nextTenFixtures);

        const db = getDatabase();
        const oddsState = {};
        const prizePotState = {};

        for (const fixture of nextTenFixtures) {
          const fixtureRef = ref(db, `fixtures/${fixture.fixture.id}`);
          const snapshot = await get(fixtureRef);

          if (!snapshot.exists()) {
            const newFixture = {
              homeTeam: fixture.teams.home.name,
              awayTeam: fixture.teams.away.name,
              odds: { teamA: 0.34, draw: 0.34, teamB: 0.34 },
              prizePot: 0,
            };

            await set(fixtureRef, newFixture);
            oddsState[fixture.fixture.id] = newFixture.odds;
            prizePotState[fixture.fixture.id] = newFixture.prizePot;
          } else {
            const fixtureData = snapshot.val();
            oddsState[fixture.fixture.id] = fixtureData.odds;
            prizePotState[fixture.fixture.id] = fixtureData.prizePot || 0;
          }
        }

        setOdds(oddsState);
        setPrizePots(prizePotState);
      } catch (error) {
        console.error("Error fetching fixture data:", error);
      }
    };

    fetchUpcomingFixtures();
  }, []);

  

  const submitPickToFirebase = async (userId, fixtureId, pick, betAmount) => {
    try {
      const db = getDatabase();
      const pickRef = ref(db, `picks/${userId}/${fixtureId}`);
      await set(pickRef, {
        pick,
        betAmount,
        timestamp: new Date().toISOString(),
        awarded: false, // Add awarded flag
      });
  
      alert("Pick and Bet submitted successfully!");
    } catch (error) {
      console.error("Error submitting pick:", error);
      alert("An error occurred. Please try again.");
    }
  };
  

  const handlePickSubmit = (fixtureId, pick) => {
    setSelectedPick(pick);
    setSelectedFixture(fixtureId);
  
    // Get the current odds for the selected pick
    const currentOdds = odds[fixtureId]?.[pick] || 0.34;
  
    // Calculate potential return based on user tokens and current odds
    const { potentialReturn, returnPercentage } = calculatePotentialReturn(userTokens, currentOdds);
  
    // Update the state for potential winnings
    setPotentialWinnings({
      tokens: potentialReturn,
      percentage: returnPercentage
    });
  };
  

  const handleBetSubmit = async (fixture) => {
    const parsedBetAmount = parseFloat(betAmount);
  
    console.log("Initial Bet Submission Details:");
    console.log("Parsed Bet Amount:", parsedBetAmount);
    console.log("Selected Pick:", selectedPick);
    console.log("Current User:", currentUser);
  
    if (!parsedBetAmount || parsedBetAmount <= 0) {
      alert("Please enter a valid bet amount.");
      return;
    }
  
    if (parsedBetAmount > userTokens) {
      alert("Insufficient tokens");
      return;
    }
  
    try {
      const db = getDatabase();
      const fixtureRef = ref(db, `fixtures/${fixture.fixture.id}`);
      const userRef = ref(db, `users/${currentUser.uid}`);
  
      // Fetch current fixture and user data
      const [fixtureSnapshot, userSnapshot] = await Promise.all([get(fixtureRef), get(userRef)]);
      const fixtureData = fixtureSnapshot.val();
      const userData = userSnapshot.val();
  
      console.log("Fixture Data:", fixtureData);
      console.log("User Data:", userData);
  
      // Ensure we have odds for the selected pick
      const currentOdds = fixtureData.odds[selectedPick] || 0.34;
      console.log("Current Odds for Selected Pick:", currentOdds);
  
      // Calculate potential return based on bet amount and current odds
      const { potentialReturn, returnPercentage } = calculatePotentialReturn(
        parsedBetAmount, 
        currentOdds
      );
      
      console.log("Calculation Details:");
      console.log("Potential Return:", potentialReturn);
      console.log("Return Percentage:", returnPercentage);
  
      // Perform all necessary updates to Firebase
      const updates = {};
  
      // Update fixture data with new bet volumes (home, draw, away bets)
      let updatedHomeBets = fixtureData.homeBets || 0;
      let updatedDrawBets = fixtureData.drawBets || 0;
      let updatedAwayBets = fixtureData.awayBets || 0;
  
      switch (selectedPick) {
        case "home":
          updatedHomeBets += parsedBetAmount;
          break;
        case "draw":
          updatedDrawBets += parsedBetAmount;
          break;
        case "away":
          updatedAwayBets += parsedBetAmount;
          break;
      }
  
      // Calculate new odds
      const totalBetAmount = updatedHomeBets + updatedDrawBets + updatedAwayBets;
      let updatedOdds = {
        teamA: Math.max(0.3, Math.min(0.5, updatedHomeBets / totalBetAmount)),
        draw: Math.max(0.3, Math.min(0.5, updatedDrawBets / totalBetAmount)),
        teamB: Math.max(0.3, Math.min(0.5, updatedAwayBets / totalBetAmount)),
      };
  
      const totalOdds = updatedOdds.teamA + updatedOdds.draw + updatedOdds.teamB;
      updatedOdds = {
        teamA: updatedOdds.teamA / totalOdds,
        draw: updatedOdds.draw / totalOdds,
        teamB: updatedOdds.teamB / totalOdds,
      };
  
      console.log("Updated Bet Volumes:", {
        homeBets: updatedHomeBets,
        drawBets: updatedDrawBets,
        awayBets: updatedAwayBets
      });
      console.log("Updated Odds:", updatedOdds);
  
      // Update fixture data with the new odds
      updates[`fixtures/${fixture.fixture.id}/odds`] = updatedOdds;
      updates[`fixtures/${fixture.fixture.id}/homeBets`] = updatedHomeBets;
      updates[`fixtures/${fixture.fixture.id}/drawBets`] = updatedDrawBets;
      updates[`fixtures/${fixture.fixture.id}/awayBets`] = updatedAwayBets;
  
      // Update user tokens
      const newUserTokens = userData.tokens - parsedBetAmount;
      updates[`users/${currentUser.uid}/tokens`] = newUserTokens;
  
      // Prepare pick data for Firebase
      const pickData = {
        pick: selectedPick,
        betAmount: parsedBetAmount,
        potentialReturns: potentialReturn,
        returnPercentage: returnPercentage,
        timestamp: new Date().toISOString(),
        awarded: false,
        originalOdds: fixtureData.odds || { teamA: 0.34, draw: 0.34, teamB: 0.34 },
        currentOdds: updatedOdds,
      };
  
      console.log("Pick Data to be Stored:", pickData);
  
      // Store the pick with the bet amount and potential returns
      updates[`picks/${currentUser.uid}/${fixture.fixture.id}`] = pickData;
  
      // Update the prize pot
      const currentPrizePot = fixtureData.prizePot || 0;
      updates[`fixtures/${fixture.fixture.id}/prizePot`] = currentPrizePot + parsedBetAmount;
  
      console.log("Full Updates Object:", updates);
  
      // Perform updates atomically
      await update(ref(db), updates);
  
      // Update local state
      setUserTokens(newUserTokens);
      setBetAmount("");
      setSelectedFixture(null);
      setSelectedPick("");
  
      // Success message with potential return
      alert(`Bet of ${parsedBetAmount} tokens placed successfully. 
      Potential returns: ${potentialReturn} tokens if you win!`);
  
    } catch (error) {
      console.error("Error placing bet:", error);
      alert("An error occurred while placing your bet. Please try again.");
    }
  };

// Modify calculatePotentialReturn to provide more detailed logging
const calculatePotentialReturn = (betAmount, currentOdds) => {
    console.log("Calculating Potential Return:");
    console.log("Bet Amount:", betAmount);
    console.log("Current Odds:", currentOdds);
  
    if (currentOdds > 0) {
      // Calculate potential return including the original bet amount
      const potentialReturn = Math.round(betAmount * (1 / currentOdds));
      const returnPercentage = Math.round(((potentialReturn - betAmount) / betAmount) * 100);
  
      console.log("Calculated Potential Return:", potentialReturn);
      console.log("Return Percentage:", returnPercentage);
  
      return { 
        potentialReturn, 
        returnPercentage 
      };
    }
  
    console.log("Default Return (No Odds):", betAmount);
    return { 
      potentialReturn: betAmount, 
      returnPercentage: 0 
    };
  };
  

  {fixtures.map((fixture) => (
    <div key={fixture.fixture.id} className="fixture-card">
      <div>
        {fixture.teams.home.name} vs {fixture.teams.away.name}
        <div>
          Odds: 
          <span>Home: {odds[fixture.fixture.id]?.teamA.toFixed(2) || 0.34.toFixed(2)}</span> | 
          <span>Draw: {odds[fixture.fixture.id]?.draw.toFixed(2) || 0.34.toFixed(2)}</span> | 
          <span>Away: {odds[fixture.fixture.id]?.teamB.toFixed(2) || 0.34.toFixed(2)}</span>
        </div>
  
        <div className="pick-buttons">
        <button 
  onClick={() => {
    handlePickSubmit(fixture.fixture.id, "home");
    // Calculate potential return for home pick
    const homeOdds = odds[fixture.fixture.id]?.teamA || 0.34;
    const potentialWinnings = calculatePotentialReturn(userTokens, homeOdds);
    setPotentialWinnings(potentialWinnings); // Set the calculated potential return
  }}
>
  Pick Home
</button>
<button 
  onClick={() => {
    handlePickSubmit(fixture.fixture.id, "draw");
    // Calculate potential return for draw pick
    const drawOdds = odds[fixture.fixture.id]?.draw || 0.34;
    const potentialWinnings = calculatePotentialReturn(userTokens, drawOdds);
    setPotentialWinnings(potentialWinnings); // Set the calculated potential return
  }}
>
  Pick Draw
</button>
<button 
  onClick={() => {
    handlePickSubmit(fixture.fixture.id, "away");
    // Calculate potential return for away pick
    const awayOdds = odds[fixture.fixture.id]?.teamB || 0.34;
    const potentialWinnings = calculatePotentialReturn(userTokens, awayOdds);
    setPotentialWinnings(potentialWinnings); // Set the calculated potential return
  }}
>
  Pick Away
</button>

        </div>
  
        {selectedFixture === fixture.fixture.id && selectedPick && (
          <div className="bet-input">
            <input
              type="number"
              value={betAmount}
              onChange={(e) => {
                // Recalculate potential winnings when bet amount changes
                const currentOdds = odds[fixture.fixture.id]?.[selectedPick] || 0.34;
                const betValue = parseFloat(e.target.value) || 0;
                
                const potentialReturn = currentOdds > 0 
                  ? Math.round(betValue * currentOdds) // Corrected calculation for potential winnings
                  : betValue;
                
                const returnPercentage = Math.round(((potentialReturn - betValue) / betValue) * 100);
                
                setPotentialWinnings({
                  tokens: potentialReturn,
                  percentage: returnPercentage
                });
                
                setBetAmount(e.target.value);
              }}
              placeholder="Enter bet amount"
              max={userTokens}
              min={1}
            />
            {betAmount && (
              <div className="winnings-explanation">
                If you win, you could get {potentialWinnings.tokens} tokens 
                ({potentialWinnings.percentage}% return)
              </div>
            )}
            <button onClick={() => handleBetSubmit(fixture)}>
              Submit Bet
            </button>
          </div>
        )}
  
        {/* After fixture ends, validate the outcome */}
        {fixture.fixture.status.short === "FT" && selectedPick && (
          <div className="bet-result">
            {/* Check if the fixture outcome matches the selected pick */}
            {(
              (selectedPick === "home" && fixture.goals.home > fixture.goals.away) ||
              (selectedPick === "away" && fixture.goals.home < fixture.goals.away) ||
              (selectedPick === "draw" && fixture.goals.home === fixture.goals.away)
            ) ? (
              <div className="bet-won">
                Congratulations! You win {potentialWinnings.tokens} tokens ({potentialWinnings.percentage}% return).
              </div>
            ) : (
              <div className="bet-lost">
                Sorry, you lost this bet.
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ))}
  
  
  
  const checkResultsAndAwardTokens = async () => {
    try {
      const db = getDatabase();
      for (const fixture of fixtures) {
        // Only check finished matches
        if (fixture.fixture.status.short === "FT") {
          const fixtureRef = ref(db, `fixtures/${fixture.fixture.id}`);
          const snapshot = await get(fixtureRef);
  
          if (snapshot.exists()) {
            const result =
              fixture.goals.home > fixture.goals.away
                ? "home"
                : fixture.goals.home < fixture.goals.away
                ? "away"
                : "draw";
  
            const pickRef = ref(db, `picks/${currentUser.uid}/${fixture.fixture.id}`);
            const pickSnapshot = await get(pickRef);
  
            if (pickSnapshot.exists()) {
              const { pick, awarded, potentialReturn } = pickSnapshot.val();
  
              // Check if the pick matches the result and tokens were not already awarded
              if (pick === result && !awarded) {
                const userRef = ref(db, `users/${currentUser.uid}`);
                const userSnapshot = await get(userRef);
                const currentTokens = userSnapshot.exists() ? userSnapshot.val().tokens : 0;
  
                const newTokenBalance = currentTokens + potentialReturn; // Award the potential return
  
                // Perform updates
                await update(userRef, { tokens: newTokenBalance }); // Update user tokens
                await update(pickRef, { awarded: true }); // Mark pick as awarded
  
                console.log(`Awarded ${potentialReturn} tokens for correct prediction in ${fixture.teams.home.name} vs ${fixture.teams.away.name}`);
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error checking results and awarding tokens:", error);
    }
  };
  

  return (
    <div className="container">
  <div>Available Tokens: {userTokens}</div>
  <h2>Upcoming Fixtures</h2>
  {fixtures.map((fixture) => (
    <div key={fixture.fixture.id} className="fixture-card">
      <div>
        {fixture.teams.home.name} vs {fixture.teams.away.name}
        <div>
          Odds: 
          <span>Home: {odds[fixture.fixture.id]?.teamA.toFixed(2) || 0.34}</span> | 
          <span>Draw: {odds[fixture.fixture.id]?.draw.toFixed(2) || 0.34}</span> | 
          <span>Away: {odds[fixture.fixture.id]?.teamB.toFixed(2) || 0.34}</span>
        </div>

        {/* Show result if fixture is finished */}
        {fixture.fixture.status.short === "FT" && (
          <div className="result-display">
            Result:{" "}
            {fixture.goals.home > fixture.goals.away
              ? "Home"
              : fixture.goals.home < fixture.goals.away
              ? "Away"
              : "Draw"}
          </div>
        )}

        {/* Bet pick buttons */}
        {fixture.fixture.status.short !== "FT" && (
          <div className="pick-buttons">
            <button onClick={() => handlePickSubmit(fixture.fixture.id, "home")}>
              Pick Home
            </button>
            <button onClick={() => handlePickSubmit(fixture.fixture.id, "draw")}>
              Pick Draw
            </button>
            <button onClick={() => handlePickSubmit(fixture.fixture.id, "away")}>
              Pick Away
            </button>
          </div>
        )}
      </div>

      {/* Bet input and potential winnings */}
      {selectedFixture === fixture.fixture.id && selectedPick && (
        <div className="bet-input">
     <input
  type="number"
  value={betAmount}
  onChange={(e) => {
    // Recalculate potential return when bet amount changes
    const currentOdds = odds[fixture.fixture.id]?.[selectedPick] || 0.34;
    const betValue = parseFloat(e.target.value) || 0;

    // Calculate potential return using the correct formula
    const { potentialReturn, returnPercentage } = calculatePotentialReturn(betValue, currentOdds);

    setPotentialWinnings({
      tokens: potentialReturn,
      percentage: returnPercentage
    });

    setBetAmount(e.target.value);
  }}
  placeholder="Enter bet amount"
  max={userTokens}
  min={1}
/>

          {betAmount && (
            <div className="winnings-explanation">
              If you win, you could get {potentialWinnings.tokens} tokens 
              ({potentialWinnings.percentage}% return)
            </div>
          )}
          <button onClick={() => handleBetSubmit(fixture)}>
            Submit Bet
          </button>
        </div>
      )}
    </div>
  ))}
</div>

  );
}

export default FootballFixtures;
