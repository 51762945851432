import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getDatabase, ref, get } from 'firebase/database';
import { auth } from '../firebase';
import { Bar } from 'react-chartjs-2'; // Importing Chart.js for bar chart

function Admin() {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [countryCounts, setCountryCounts] = useState({});
  const [mostPopularCountry, setMostPopularCountry] = useState('Unknown');
  const [recentActivities, setRecentActivities] = useState([]); // To store recent user activity
  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const allowedEmails = ['denis@kingjtech.com', 'denis@atomtech.live', 'support@predictify.app', 'jeremy@fall.com.mt'];
        if (allowedEmails.includes(user.email)) {
          setIsAuthorized(true);
        } else {
          navigate('/');
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchUsers = async () => {
    try {
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const usersArray = Object.keys(usersData).map((id) => ({
          id,
          ...usersData[id],
        }));
        setUsers(usersArray);
        countNewUsers(usersArray);
        countUsersByCountry(usersArray);
        countActiveUsers(usersArray);
        fetchRecentActivities(usersArray); // Fetch recent user activities
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const allowedEmails = ['denis@kingjtech.com', 'denis@atomtech.live', 'support@predictify.app', 'jeremy@fall.com.mt'];
        if (allowedEmails.includes(user.email)) {
          setIsAuthorized(true);
          fetchUsers();
        } else {
          navigate('/');
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const countNewUsers = (usersArray) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const newUsers = usersArray.filter((user) => {
      const registrationDate = new Date(user.timestamp);
      return (
        registrationDate.getMonth() === currentMonth &&
        registrationDate.getFullYear() === currentYear
      );
    });
    setNewUsersCount(newUsers.length);
  };

  const countActiveUsers = (usersArray) => {
    const activeUsers = usersArray.filter(user => user.userStatus === 'active');
    setActiveUsersCount(activeUsers.length);
  };

  const countUsersByCountry = (usersArray) => {
    const countryCount = {};
    usersArray.forEach(user => {
      const country = user.country || 'Unknown';
      countryCount[country] = (countryCount[country] || 0) + 1;
    });
    setCountryCounts(countryCount);

    const mostPopular = Object.entries(countryCount).reduce((max, [country, count]) => {
      return count > max.count ? { country, count } : max;
    }, { country: 'Unknown', count: 0 });

    setMostPopularCountry(mostPopular.country);
  };

  const fetchRecentActivities = (usersArray) => {
    const recent = usersArray
      .map(user => ({
        email: user.email,
        lastLoginDate: user.lastLoginDate,
        status: user.userStatus,
      }))
      .sort((a, b) => new Date(b.lastLoginDate) - new Date(a.lastLoginDate)); // Sort by last login date
    setRecentActivities(recent.slice(0, 5)); // Show only the latest 5 activities
  };

  const getMonthRange = () => {
    const currentDate = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthName = monthNames[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return `1st to the ${new Date(year, currentDate.getMonth() + 1, 0).getDate()} of ${monthName}`;
  };


  if (!isAuthorized) {
    return null;
  }

  return (
    <div>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1 p-3">
          <h1 className="text-center">Admin Dashboard</h1>
          <hr className="w-25 mx-auto" />
          
          <div className="container mb-4">
            <div className="row g-3">
              <div className="col-md-6">
                <div className="card shadow text-center">
                  <div className="card-body">
                    <h3 className="display-6 fw-bold">{newUsersCount}</h3>
                    <p className="text-secondary">
                      <strong>New Users This Month ({getMonthRange()})</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card shadow text-center">
                  <div className="card-body">
                    <h3 className="display-6 fw-bold">{users.length}</h3>
                    <p className="text-secondary">Total Users</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card shadow text-center">
                  <div className="card-body">
                    <h3 className="display-6 fw-bold">{activeUsersCount}</h3>
                    <p className="text-secondary">Current Active Users</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
  <div className="card shadow text-center">
    <div className="card-body">
      <h3 className="display-6 fw-bold">{mostPopularCountry}</h3>
      <p className="text-secondary">Most Popular Country - {countryCounts[mostPopularCountry]} users</p>
    </div>
  </div>
</div>
<p className='text-center'><a href='https://analytics.google.com/analytics/web/?authuser=0&hl=en#/p467506460/reports/intelligenthome?params=_u..nav%3Dmaui'>Click here to view full analytics</a></p>

                  {/* Recent Activity Feed */}
          <div className="mb-4">
            <h3 className="text-center mb-3">Recent User Activity</h3>
            <ul className="list-group">
              {recentActivities.map((activity, index) => (
                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{activity.email}</strong> - {activity.status}
                  </div>
                  <small>{new Date(activity.lastLoginDate).toLocaleString()}</small>
                </li>
              ))}
            </ul>
          </div>
            </div>
          </div>
      
        </div>
      </div>
    </div>
  );
}

export default Admin;
